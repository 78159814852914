.footer {
  &__container {
    padding-top: 36px;
    padding-bottom: 36px;

    @include mq('medium') {
      padding-top: 53px;
      padding-bottom: 50px;
    }

    @include mq('medium-plus') {
      padding-top: 52px;
    }
  }

  &__wrapper {
    @include mq('medium') {
      padding-right: 30px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between;

      &.cmp-container {
        max-width: 972px; // as per live
        margin-left: auto;
        margin-right: auto;
      }

      > .container {
        &:last-child {
          @include mq('medium') {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  &__description-container {
    &.cmp-container {
      margin-bottom: 18px;

      @include mq('medium') {
        margin-bottom: var(--emu-semantic-spacing-none);
        padding-right: 20px;
        max-width: 765px; // as per live site
      }
    }
  }

  &__links {
    &.cmp-container {
      @include mq('medium') {
        margin-bottom: 20px;
      }

      @include mq('large') {
        margin-bottom: 21px;
      }
    }

    ul {
      padding-left: var(--emu-semantic-spacing-none);
      margin-top: var(--emu-semantic-spacing-none);
      margin-bottom: var(--emu-semantic-spacing-none);
      font-size: 11.5px;
      line-height: 22px;
      letter-spacing: 0.35px;
    }

    li {
      list-style-type: none;
      display: inline-block;
      position: relative;

      &:not(:first-child) {
        a {
          padding-left: 12px;
          margin-left: 6px;

          &::before {
            content: '';
            position: absolute;
            top: 5px;
            left: 6px;
            width: 1px; // as per live
            height: 10px; // as per live
            background-color: var(--emu-common-colors-grey-700);
          }
        }
      }

      &:last-child {
        a {
          padding-right: var(--emu-common-spacing-none);
        }
      }

      a {
        text-decoration: none;
        color: var(--emu-common-colors-grey-700);
        padding-right: var(--emu-common-spacing-xs);

        &:hover {
          color: var(--emu-common-colors-violet-600);
        }
      }
    }
  }

  &__description {
    p {
      font-size: 11.5px;
      line-height: 14px;
      letter-spacing: 0.4px;
      margin-top: 15px;
    }

    .emphasis {
      letter-spacing: 0.5px;
    }
  }

  &__logo-container {
    width: 98px; // as per live

    @include mq('medium') {
      width: auto;
    }

    img {
      @include mq('medium') {
        margin-top: -32px;
        height: 113px; // as per live site
      }
    }
  }
}

[lang='fr_ca'] {
  .footer {
    &__description p br {
      display: none;

      @include mq('medium-plus') {
        display: block;
      }
    }

    // breakpoint needed to match live site
    @include mq('790px') {
      &__links li {
        display: inline;

        &:not(:first-child) {
          a::before {
            top: 0;
          }
        }
      }
    }
  }
}
