header {
  .btx-header {
    @include mq('medium') {
      background: var(--emu-common-colors-white)
        url('../../assets/images/body-background.png') repeat-x left top;
    }

    // main wrapper of the header top bar
    &__wrapper {
      border-bottom: var(--emu-common-border-width-thin) solid
        var(--emu-common-colors-grey-500);
      position: relative;
      display: flex;
      min-height: 96px; // from live site
      padding-left: 20px;
      padding-right: 20px;
      justify-content: space-between;
      align-items: center;

      @include mq('medium') {
        min-height: 132px; // from live site
        align-items: flex-end;
        background-color: var(--emu-common-colors-transparent);
      }

      @include mq('medium-plus') {
        max-width: 972px; // as per live site
        margin-left: auto;
        margin-right: auto;
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      // logo container
      > .image {
        @include mq('medium') {
          height: 80px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    &__logo {
      img {
        width: 108px; // as per live site

        @include mq('medium') {
          width: 153px;
        }
      }
    }

    &__mobile-cta {
      &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
        padding: var(--emu-common-spacing-none);
        border: var(--emu-common-border-width-none);
        display: block;
        line-height: 0;

        &,
        &:hover,
        &:focus,
        &:active {
          background-color: var(--emu-common-colors-transparent);
          border: var(--emu-common-border-width-none);
        }

        svg {
          margin: var(--emu-common-spacing-none);
          position: relative;
          top: -2px;
        }
      }
    }

    // mobile menu toggle button. increasing specificity by adding full class name
    .btx-header__toggle {
      // increasing specificity
      &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
        width: 28px;

        &:hover {
          svg {
            fill: #5b358d; // no variable for this color
          }
        }

        svg {
          display: block;
          width: 28px;
          fill: var(--emu-common-colors-violet-200);
          transition: fill var(--emu-common-other-time-transition-short);
          transform: scale(
            1.09
          ); // actual image is not rendering the height as live site. Had to add this specifically
        }
      }
    }

    // navigation dropdown for the mobile
    &__mobile-nav {
      &-container {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--emu-common-other-z-index-header);

        @include mq('medium') {
          display: none;
        }

        &.js-toggle-on {
          display: block;
        }

        .aaaem-container {
          background-color: inherit;
        }
      }

      &-topbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-right: 15px;
        padding-bottom: 20px;
        padding-left: var(--emu-common-spacing-small);
        border-bottom: var(
            --emu-component-lists-navigation-banner-item-border-width
          )
          solid
          var(
            --emu-component-lists-navigation-banner-item-color-border-default-light
          );

        .btx-header__logo img {
          width: 100px; // as per live site
        }
      }

      &-close {
        // increasing specificity
        &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
        }
      }
    }

    &__lang-switch {
      &-container {
        padding-left: 20px;
        display: flex;
        margin-bottom: 5px;
        margin-top: 20px;

        @include mq('medium') {
          position: fixed;
          z-index: var(--emu-common-other-z-index-overlay);
          top: 0;
          right: 12%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          background-color: var(--emu-common-colors-violet-50);
          margin: var(--emu-common-spacing-none);
          padding: var(--emu-common-spacing-none);
          z-index: var(--emu-common-other-z-index-header);
        }

        > .button {
          &:not(:last-child) {
            a,
            button {
              // increasing specificity
              &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
                border-right: var(--emu-common-border-width-none);
              }
            }
          }

          a,
          button {
            // increasing specificity
            &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
              padding-top: 6px;
              padding-right: 12px;
              padding-bottom: 5px;
              padding-left: 12px;
              font-size: var(--emu-common-font-sizes-narrow-medium);
              line-height: normal;
              letter-spacing: 0.25px;

              @include mq('medium') {
                border: var(--emu-common-border-width-none);
                transition: all var(--emu-common-other-time-transition-short);
                padding-top: 9px;
                padding-right: var(--emu-common-spacing-small);
                padding-bottom: 6px;
                padding-left: var(--emu-common-spacing-small);
                font-size: 14px;
                line-height: 1;
              }

              &:hover,
              &:active,
              &:focus {
                @include mq('medium') {
                  background-color: var(--emu-common-colors-violet-800);
                }
              }
            }
          }
        }
      }

      &-cta {
        &--active {
          // increasing specificity
          &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
            @include mq('medium') {
              font-family: var(--emu-semantic-font-families-heading);
            }
          }
        }
      }

      &-separator {
        @include mq('medium') {
          position: relative;
          top: 2px;
        }

        p {
          @include mq('medium') {
            font-size: 14px;
          }
        }
      }
    }

    &__mobile-secondary-nav {
      // needed for specificity
      ul li a {
        border: var(--emu-common-border-width-none);
      }
    }
  }
}
