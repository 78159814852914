// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  @include mq('768px', 'max-width') {
    display: none !important; // making sure that this is not effected by any other styles
  }

  @include aem-editor-view {
    display: block !important; // making sure that this is not effected by any other styles
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none !important; // making sure that this is not effected by any other styles
  }

  @include aem-editor-view {
    display: block !important; // making sure that this is not effected by any other styles
  }
}

// padding adjustments
.no-pad {
  padding: var(
    --emu-common-spacing-none
  ) !important; // making sure that this is not effected by any other styles
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

// content related util classes. Used to center buttons mostly.
.u-content--centered {
  text-align: center;
}

// Utility classes for container max widths.
.u-container--default {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;

  @include mq('medium-plus') {
    max-width: 972px; //as per live
  }
}

// Utility classes for background colors
.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--grey {
  background-color: var(--emu-common-colors-grey-400);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-grey-300);
}

.u-bg-color--lighter-violet {
  background-color: var(--emu-common-colors-violet-50);
}

.u-bg-color--light-violet {
  background-color: var(--emu-common-colors-violet-200);
}

.u-bg-color--violet {
  background-color: var(--emu-common-colors-violet-300);
}

.u-bg-color--dark-violet {
  background-color: var(--emu-common-colors-violet-600);
}

.u-bg-color--darker-violet {
  background-color: var(--emu-common-colors-violet-800);
}

// Utility classes for text colors
[class*='u-text--color'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span,
  button {
    color: inherit;
  }
}

.u-text--color-white {
  color: var(--emu-common-colors-white);
}

.u-text--color-black {
  color: var(--emu-common-colors-black);
}

.u-text--color-lighter-violet {
  color: var(--emu-common-colors-violet-100);
}

.u-text--color-light-violet {
  color: var(--emu-common-colors-violet-400);
}

.u-text--color-violet {
  color: var(--emu-common-colors-violet-500);
}

.u-text--color-medium-violet {
  color: var(--emu-common-colors-violet-600);
}

.u-text--color-dark-violet {
  color: var(--emu-common-colors-violet-700);
}

.u-text--color-darker-violet {
  color: var(--emu-common-colors-violet-900);
}

.u-text--color-light-grey {
  color: var(--emu-common-colors-grey-500);
}

.u-text--color-grey {
  color: var(--emu-common-colors-grey-600);
}

.u-text--color-dark-grey {
  color: var(--emu-common-colors-grey-700);
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading) !important;
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body) !important;
}

.u-font-family--mono {
  font-family: var(--emu-semantic-font-families-mono) !important;
}

[class*='u-font-family--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span {
    font-family: inherit !important;
  }
}
