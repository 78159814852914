#sitemap {
  .title-text {
    &__wrapper {
      padding-bottom: 27px;

      @include mq('medium-plus') {
        padding-bottom: 37px;
      }
    }
  }

  .sitemap {
    &__links {
      ul {
        padding-left: 15px;
        margin-bottom: var(--emu-semantic-spacing-none);

        li {
          list-style-type: none;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 6px; // as per live
            height: 6px; // as per live
            border-radius: 100%;
            left: -15px;
            top: 7px;
            background-color: var(--emu-common-colors-violet-700);
          }

          a {
            color: var(--emu-common-colors-violet-700);
            letter-spacing: 0.4px; //adjusted to match live
            font-size: 15.5px;
            line-height: 19px;
          }
        }
      }
    }
  }
}
