html {
  -webkit-text-size-adjust: 100%;
}

html,
body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }
  .experiencefragment {
    padding: var(--emu-common-spacing-none);
  }
}

body {
  background-color: var(--emu-common-colors-grey-300);
  font-family: var(--emu-semantic-font-families-body);
  position: relative;
  color: var(--emu-common-colors-black);
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

sup {
  font-size: 50%;
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: var(--emu-common-spacing-none);
}

p,
li {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}
