.getting-started {
  &__faq {
    margin-top: 29px;
    margin-bottom: 10px;

    @include mq('medium') {
      margin-bottom: 20px;
    }

    > .text:first-child {
      .getting-started__faq-item {
        padding-top: var(--emu-semantic-spacing-none);
        padding-bottom: 14px;
        border: none;

        h3 {
          top: 0;
        }
      }
    }

    &-item {
      padding-top: var(--emu-common-spacing-medium);
      padding-right: 20px;
      padding-bottom: 14px;
      padding-left: 30px;
      border-top: var(--emu-common-sizing-xxs) solid
        var(--emu-common-colors-grey-200);
      position: relative;

      h3 {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: var(--emu-semantic-spacing-none);
        color: var(--emu-common-colors-violet-900);
        font-family: var(--emu-semantic-font-families-heading);
        position: absolute;
        top: 17px;
        left: 0;
        line-height: 1;
      }

      h4 {
        margin-top: var(--emu-semantic-spacing-none);
        margin-bottom: 6px;
        font-size: 13.5px;
        line-height: 22px;
        letter-spacing: 0.2px;

        sup {
          font-size: 9px;
        }
      }

      p {
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.625px;

        .emphasis {
          letter-spacing: 0.9px;
        }

        sup {
          font-size: 10px;
          line-height: 10px;
        }
      }
    }
  }
}

[lang='fr_ca'] {
  .getting-started__faq-item p {
    letter-spacing: 0.58px;
  }
}
