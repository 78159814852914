.error {
  padding-top: 88px;
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: 88px;
  padding-left: var(--emu-common-spacing-medium);

  @include mq('medium') {
    padding-top: 164px;
    padding-bottom: 164px;
  }

  &__title {
    .cmp-title__text {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-medium);
      font-size: 96px;
      line-height: 1;
      letter-spacing: 4.8px;

      @include mq('medium') {
        font-size: 160px;
        letter-spacing: 8px;
      }
    }
  }

  &__subtitle {
    .cmp-title__text {
      margin-bottom: var(--emu-common-spacing-medium);
      letter-spacing: 0.2px;
      line-height: 1;
    }
  }

  &__desc {
    p {
      letter-spacing: 0.6px;
    }
  }

  .button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &__cta {
    // increasing specificity
    &.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
      height: 40px;
      line-height: 43px;
      min-width: 150px;
      justify-content: center;
      letter-spacing: 0.8px;
      padding-top: var(--emu-common-spacing-none);
      padding-right: var(--emu-common-spacing-medium);
      padding-bottom: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-large);
      margin-bottom: var(--emu-common-spacing-large);
    }
  }
}
