.title-text {
  &__wrapper {
    padding-top: 34px;
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;

    @include mq('medium') {
      padding-right: 30px;
      padding-left: 30px;
    }

    // breakpoint needed as per live site
    @include mq('972px') {
      padding-top: 44px;
      padding-right: 40px;
      padding-bottom: 40px;
      padding-left: 40px;
    }
  }

  &__title {
    margin-bottom: 19px;

    .cmp-title__text {
      margin-top: var(--emu-semantic-spacing-none);
      margin-bottom: var(--emu-semantic-spacing-none);
      line-height: 36px;
    }
  }

  &__description {
    p {
      margin-bottom: 21px;
      letter-spacing: 0.4px;
      font-size: 15.5px; // to match the live site
      line-height: 26px;

      @include mq('medium') {
        letter-spacing: 0.45px;
      }

      .emphasis {
        letter-spacing: 0.8px;

        @include mq('medium') {
          letter-spacing: 0.4px;
        }
      }
    }
  }
}

[lang='fr_ca'] {
  .title-text__description p {
    letter-spacing: 0.5px;
  }
}
