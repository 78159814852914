#home {
  .banner {
    &__wrapper {
      margin-bottom: var(--emu-common-spacing-small);
      overflow: hidden;
    }

    &__image {
      padding-left: 20px;
      padding-right: 20px;

      @include mq('medium') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      img {
        width: 100%;
        display: flex;

        @include mq(medium) {
          height: 330px; //as per live
          object-fit: cover;
          object-position: left top;
        }
      }
    }
  }

  .locator {
    display: flex;
    flex-direction: column;
    padding-top: var(--emu-common-spacing-large); //to match the design
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;

    @include mq('medium') {
      margin-bottom: var(--emu-common-spacing-small);
      flex-direction: row;
      min-height: 200px; // as per live site
      padding-top: 20px;
      padding-right: var(--emu-common-spacing-none);
      padding-bottom: 20px;
      padding-left: var(--emu-common-spacing-none);
      align-items: center;
      margin-bottom: var(--emu-common-spacing-medium);
    }

    > .container {
      &:first-child {
        @include mq('medium') {
          width: 50%; //as per live
        }
      }

      &:last-child {
        margin-left: var(--emu-common-spacing-small);
        position: relative;

        @include mq('medium') {
          margin-left: 20%; // as per live
        }
      }
    }

    &__details {
      @include mq('medium') {
        padding-top: 20px;
        padding-bottom: 22px;
        padding-left: 26px;
      }

      @include mq('medium-plus') {
        padding-bottom: 24px;
      }

      &-title {
        .cmp-title__text {
          font-size: 22px;
          line-height: 1.181818182;
          margin-bottom: 14px;
          margin-top: var(--emu-common-spacing-none);
          letter-spacing: 0.4px;
          font-weight: var(--emu-common-font-weight-600);

          @include mq('medium') {
            font-size: 28px;
            line-height: 1.392857143;
            letter-spacing: 0.6px;
            margin-bottom: 12px;
          }

          @include mq('medium-plus') {
            margin-bottom: 13px;
          }
        }
      }

      &-description {
        p {
          font-size: 11.5px; // to match the live site
          line-height: var(--emu-common-line-heights-narrow-large);
          letter-spacing: 0.4px;
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-xs);

          @include mq('medium') {
            font-size: 13px;
            letter-spacing: 0.6px;
            line-height: 21px;
            margin-bottom: var(--emu-common-spacing-xxs);
          }

          sup {
            font-size: 50%;
            line-height: 0;
          }
        }

        .emphasis {
          letter-spacing: 0.6px;

          @include mq('medium') {
            letter-spacing: 0.9px;
          }
        }
      }
    }

    &__find-a-clinic-cta {
      font-size: 15px; // to match live site
      padding-top: 9px; //to match live site
      padding-right: 30px;
      padding-bottom: 5px; // to match live site
      padding-left: 30px;
      line-height: 23px;
      letter-spacing: 0.95px; // to match the live site
    }
  }
}

//FR language home page styles
[lang='fr_ca'] {
  #home{
    .locator{
      &__details {
      &-description {
        p {
          @include mq('medium') {
            letter-spacing: 0.7px;
          }
        }
      } 
      }
      &__find-a-clinic-cta {
        text-align: center;
    
        @include mq('medium-plus') {
          letter-spacing: 1.1px;
        }
      } 
    } 
  }
}