#about {
  .title-text {
    &__wrapper {
      padding-bottom: 27px;

      @include mq('medium-plus') {
        padding-bottom: 37px;
      }
    }

    &__description {
      p {
        margin-bottom: 20px;
        letter-spacing: 0.3px;
      }
    }
  }
}
