:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d3d3d3;
  --emu-common-colors-grey-300: #f8f8f8;
  --emu-common-colors-grey-400: #e8e8e8;
  --emu-common-colors-grey-500: #c4c4c4;
  --emu-common-colors-grey-600: #505050;
  --emu-common-colors-grey-700: #494949;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-violet-50: #f6f0fa;
  --emu-common-colors-violet-100: #d4c6dd;
  --emu-common-colors-violet-200: #cbb0d0;
  --emu-common-colors-violet-300: #c4b1d0;
  --emu-common-colors-violet-400: #a388b8;
  --emu-common-colors-violet-500: #7b4fb5;
  --emu-common-colors-violet-600: #753796;
  --emu-common-colors-violet-650: #68408e;
  --emu-common-colors-violet-700: #572c85;
  --emu-common-colors-violet-800: #5b368d;
  --emu-common-colors-violet-900: #340c36;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #faf6f5;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #ead7d2;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #2b1718;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fdfcfc;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #fcf9f8;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #fdfcfc;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-600: 600;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 120px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-container: 1520px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-primary-900);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-semantic-colors-primary-900);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-primary-900);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-primary-900);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-500);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-semantic-colors-error-300);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-primary-650);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-medium-brown-300);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-primary-0: var(--emu-common-colors-white);
  --emu-semantic-colors-primary-50: var(--emu-common-colors-violet-50);
  --emu-semantic-colors-primary-100: var(--emu-common-colors-violet-100);
  --emu-semantic-colors-primary-200: var(--emu-common-colors-violet-200);
  --emu-semantic-colors-primary-300: var(--emu-common-colors-violet-300);
  --emu-semantic-colors-primary-400: var(--emu-common-colors-violet-400);
  --emu-semantic-colors-primary-500: var(--emu-common-colors-violet-500);
  --emu-semantic-colors-primary-600: var(--emu-common-colors-violet-600);
  --emu-semantic-colors-primary-650: var(--emu-common-colors-violet-650);
  --emu-semantic-colors-primary-700: var(--emu-common-colors-violet-700);
  --emu-semantic-colors-primary-800: var(--emu-common-colors-violet-800);
  --emu-semantic-colors-primary-900: var(--emu-common-colors-violet-900);
  --emu-semantic-colors-primary-950: var(--emu-common-colors-medium-brown-950);
  --emu-semantic-colors-primary-light: var(--emu-semantic-colors-primary-500);
  --emu-semantic-colors-primary-dark: var(--emu-semantic-colors-primary-200);
  --emu-semantic-colors-secondary-50: var(--emu-common-colors-blush-50);
  --emu-semantic-colors-secondary-100: var(--emu-common-colors-grey-100);
  --emu-semantic-colors-secondary-200: var(--emu-common-colors-grey-200);
  --emu-semantic-colors-secondary-300: var(--emu-common-colors-grey-300);
  --emu-semantic-colors-secondary-400: var(--emu-common-colors-grey-400);
  --emu-semantic-colors-secondary-500: var(--emu-common-colors-grey-500);
  --emu-semantic-colors-secondary-600: var(--emu-common-colors-grey-600);
  --emu-semantic-colors-secondary-700: var(--emu-common-colors-grey-700);
  --emu-semantic-colors-secondary-800: var(--emu-common-colors-grey-800);
  --emu-semantic-colors-secondary-900: var(--emu-common-colors-grey-900);
  --emu-semantic-colors-secondary-950: var(--emu-common-colors-blush-950);
  --emu-semantic-colors-secondary-light: var(--emu-semantic-colors-secondary-500);
  --emu-semantic-colors-secondary-dark: var(--emu-semantic-colors-secondary-200);
  --emu-semantic-colors-tertiary-50: var(--emu-common-colors-abbvie-blue-50);
  --emu-semantic-colors-tertiary-100: var(--emu-common-colors-abbvie-blue-100);
  --emu-semantic-colors-tertiary-200: var(--emu-common-colors-abbvie-blue-200);
  --emu-semantic-colors-tertiary-300: var(--emu-common-colors-abbvie-blue-300);
  --emu-semantic-colors-tertiary-400: var(--emu-common-colors-abbvie-blue-400);
  --emu-semantic-colors-tertiary-500: var(--emu-common-colors-abbvie-blue-500);
  --emu-semantic-colors-tertiary-600: var(--emu-common-colors-abbvie-blue-600);
  --emu-semantic-colors-tertiary-700: var(--emu-common-colors-abbvie-blue-700);
  --emu-semantic-colors-tertiary-800: var(--emu-common-colors-abbvie-blue-800);
  --emu-semantic-colors-tertiary-900: var(--emu-common-colors-abbvie-blue-900);
  --emu-semantic-colors-tertiary-950: var(--emu-common-colors-abbvie-blue-950);
  --emu-semantic-colors-tertiary-light: var(--emu-semantic-colors-tertiary-500);
  --emu-semantic-colors-tertiary-dark: var(--emu-semantic-colors-tertiary-200);
  --emu-semantic-colors-error-50: var(--emu-common-colors-red-50);
  --emu-semantic-colors-error-100: var(--emu-common-colors-red-100);
  --emu-semantic-colors-error-200: var(--emu-common-colors-red-200);
  --emu-semantic-colors-error-300: var(--emu-common-colors-red-300);
  --emu-semantic-colors-error-400: var(--emu-common-colors-red-400);
  --emu-semantic-colors-error-500: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-600: var(--emu-common-colors-red-600);
  --emu-semantic-colors-error-700: var(--emu-common-colors-red-700);
  --emu-semantic-colors-error-800: var(--emu-common-colors-red-800);
  --emu-semantic-colors-error-900: var(--emu-common-colors-red-900);
  --emu-semantic-colors-error-950: var(--emu-common-colors-red-950);
  --emu-semantic-colors-error-light: var(--emu-semantic-colors-error-500);
  --emu-semantic-colors-error-dark: var(--emu-semantic-colors-error-200);
  --emu-semantic-colors-background-light: var(--emu-common-colors-light-brown-50);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "NeueHelvetica45Light", sans-serif;
  --emu-semantic-font-families-heading: "NeueHelvetica75Bold", sans-serif;
  --emu-semantic-font-families-mono: "NeueHelvetica56Italic", sans-serif;
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-line-heights-wide-small) px;
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-line-heights-narrow-small) px;
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-small);
  --emu-semantic-spacing-horizontal-narrow: 8px;
  --emu-semantic-spacing-horizontal-wide: 16px;
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-small);
  --emu-semantic-spacing-vertical-narrow: 8px;
  --emu-semantic-spacing-vertical-wide: 16px;
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-line-heights-wide-small) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-line-heights-narrow-small) * .25);
  --emu-semantic-font-sizes-narrow-medium: 20px;
  --emu-semantic-font-sizes-narrow-large: 25px;
  --emu-semantic-font-sizes-narrow-xl: 18.24px;
  --emu-semantic-font-sizes-narrow-xxl: 29px;
  --emu-semantic-font-sizes-narrow-xxxl: 29px;
  --emu-semantic-font-sizes-narrow-small: 16px;
  --emu-semantic-font-sizes-narrow-xs: 12.8px;
  --emu-semantic-font-sizes-wide-medium: 22.5px;
  --emu-semantic-font-sizes-wide-large: 28.13px;
  --emu-semantic-font-sizes-wide-xl: 18.24px;
  --emu-semantic-font-sizes-wide-xxl: 29px;
  --emu-semantic-font-sizes-wide-xxxl: 29px;
  --emu-semantic-font-sizes-wide-small: 16px;
  --emu-semantic-font-sizes-wide-xs: 11.48px;
  --emu-semantic-line-heights-narrow-large: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-medium: calc(var(--emu-semantic-line-heights-narrow-small) * 1.5);
  --emu-semantic-line-heights-narrow-xl: 1px;
  --emu-semantic-line-heights-narrow-xxl: 1.625;
  --emu-semantic-line-heights-narrow-xxxl: 1.625;
  --emu-semantic-line-heights-narrow-small: 1.625;
  --emu-semantic-line-heights-narrow-xs: var(--emu-semantic-line-heights-narrow-small);
  --emu-semantic-line-heights-wide-large: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-medium: calc(var(--emu-semantic-line-heights-wide-small) * 1.5);
  --emu-semantic-line-heights-wide-xl: 1px;
  --emu-semantic-line-heights-wide-xxl: 1.625;
  --emu-semantic-line-heights-wide-xxxl: 1.625;
  --emu-semantic-line-heights-wide-small: 1.625;
  --emu-semantic-line-heights-wide-xs: var(--emu-semantic-line-heights-wide-small);
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-medium-plus: 990px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1280px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-semantic-font-weight-bold) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 66.6667%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-teaser-description-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-description-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-subtext-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-cards-teaser-old-padding-top-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-top-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-right-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-bottom-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-narrow: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-padding-left-wide: var(--emu-common-sizing-medium);
  --emu-component-cards-teaser-old-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-old-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-old-title-max-width: 66.6667%;
  --emu-component-cards-teaser-old-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-old-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-old-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-cards-content-fragment-card-margin-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-margin-wide: var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-typography-name-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-name-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-cards-content-fragment-card-text-typography-title-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-title-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-cards-content-fragment-card-text-typography-bio-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-cards-content-fragment-card-text-typography-bio-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-cards-content-fragment-card-text-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none);
  --emu-component-cards-content-fragment-card-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-cards-content-fragment-card-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-cards-content-fragment-card-padding-narrow: var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-cards-content-fragment-card-padding-wide: var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-cards-content-fragment-card-color-background-dark: var(--emu-semantic-colors-primary-950);
  --emu-component-cards-content-fragment-card-color-background-light: var(--emu-semantic-colors-primary-0);
  --emu-component-cards-content-fragment-card-border-radius-narrow: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-border-radius-wide: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-height-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-narrow: var(--emu-semantic-sizing-xl);
  --emu-component-cards-content-fragment-card-author-image-sizing-width-wide: var(--emu-semantic-sizing-xxl);
  --emu-component-cards-content-fragment-card-author-image-border-radius-narrow: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-cards-content-fragment-card-author-image-border-radius-wide: var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium) var(--emu-semantic-border-radius-medium);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-component-lists-accordion-margin-top-narrow) var(--emu-component-lists-accordion-margin-right-narrow) var(--emu-component-lists-accordion-margin-bottom-narrow) var(--emu-component-lists-accordion-margin-left-narrow);
  --emu-component-lists-accordion-margin-wide: var(--emu-component-lists-accordion-margin-top-wide) var(--emu-component-lists-accordion-margin-right-wide) var(--emu-component-lists-accordion-margin-bottom-wide) var(--emu-component-lists-accordion-margin-left-wide);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-component-lists-accordion-item-body-padding-bottom-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-violet-100);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-violet-100);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: 17px;
  --emu-component-lists-navigation-text-padding-top-wide: 7px;
  --emu-component-lists-navigation-text-padding-right-narrow: 20px;
  --emu-component-lists-navigation-text-padding-right-wide: 23px;
  --emu-component-lists-navigation-text-padding-bottom-narrow: 12px;
  --emu-component-lists-navigation-text-padding-bottom-wide: 12px;
  --emu-component-lists-navigation-text-padding-left-narrow: 20px;
  --emu-component-lists-navigation-text-padding-left-wide: 23px;
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-separator-size-height: var(--emu-semantic-sizing-xxs);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-semantic-spacing-half-line-height-narrow) 0px;
  --emu-component-ingredients-label-margin-wide: var(--emu-semantic-spacing-half-line-height-wide) 0px;
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-radio-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-semantic-border-radius-medium);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: var(--emu-semantic-sizing-one-line-height-narrow);
  --emu-component-ingredients-checkbox-input-control-size-all-wide: var(--emu-semantic-sizing-one-line-height-wide);
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-common-colors-medium-grey-500);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / 22.4px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / 22.4px var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-thin);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-focus-light);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-hover-light);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-active-light);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-common-colors-grey-300);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-button-padding-narrow: 10px var(--emu-common-spacing-medium) 10px var(--emu-common-spacing-medium);
  --emu-component-actions-button-padding-wide: 10px var(--emu-common-spacing-medium) 10px var(--emu-common-spacing-medium);
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: var(--emu-semantic-font-sizes-narrow-small);
  --emu-component-actions-pagination-icon-wide: var(--emu-semantic-font-sizes-wide-small);
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-semantic-colors-primary-500);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-actions-pagination-gap-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-pagination-gap-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-border-light: var(--emu-component-containers-comparison-slider-divider-color-light);
  --emu-component-containers-comparison-slider-divider-color-border-dark: var(--emu-component-containers-comparison-slider-divider-color-dark);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-border-width-narrow: var(--emu-component-containers-comparison-slider-divider-size-width-narrow);
  --emu-component-containers-comparison-slider-divider-border-width-wide: var(--emu-component-containers-comparison-slider-divider-size-width-wide);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-divider-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-handle-color-background-light: var(--emu-component-containers-comparison-slider-custom-handle-color-background-light);
  --emu-component-containers-comparison-slider-handle-color-background-dark: var(--emu-component-containers-comparison-slider-custom-handle-color-background-dark);
  --emu-component-containers-comparison-slider-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-narrow);
  --emu-component-containers-comparison-slider-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-wide);
  --emu-component-containers-comparison-slider-handle-icon-size-width-narrow: var(--emu-component-containers-comparison-slider-handle-size-width-narrow);
  --emu-component-containers-comparison-slider-handle-icon-size-width-wide: var(--emu-component-containers-comparison-slider-handle-size-width-wide);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-light: var(--emu-component-containers-comparison-slider-divider-color-border-light);
  --emu-component-containers-comparison-slider-handle-icon-color-fill-dark: var(--emu-component-containers-comparison-slider-divider-color-border-dark);
  --emu-component-containers-comparison-slider-handle-icon-thickness-narrow: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow);
  --emu-component-containers-comparison-slider-handle-icon-thickness-wide: var(--emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide);
  --emu-component-containers-comparison-slider-handle-text-typography-narrow: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-typography-wide: var(--emu-component-containers-comparison-slider-custom-handle-text-typography-narrow);
  --emu-component-containers-comparison-slider-handle-text-color-light: var(--emu-component-containers-comparison-slider-custom-handle-text-color-light);
  --emu-component-containers-comparison-slider-handle-text-color-dark: var(--emu-component-containers-comparison-slider-custom-handle-text-color-dark);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-narrow: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-narrow);
  --emu-component-containers-comparison-slider-custom-handle-padding-wide: var(--emu-component-containers-comparison-slider-custom-handle-padding-top-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-right-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide) var(--emu-component-containers-comparison-slider-custom-handle-padding-left-wide);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow) var(--emu-semantic-font-families-body);
  --emu-component-containers-comparison-slider-custom-handle-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-component-containers-comparison-slider-custom-handle-text-font-size-wide) / var(--emu-component-containers-comparison-slider-custom-handle-text-line-height-wide) var(--emu-semantic-font-families-body);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-component-containers-carousel-indicators-gap-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-component-containers-carousel-indicators-gap-horizontal-wide);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 80%;
  --emu-component-layers-modal-body-sizing-max-width: 800px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-thin);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-medium);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-common-spacing-small);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

html, body {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-spacing-none);
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: var(--emu-common-spacing-none);
}

body {
  background-color: var(--emu-common-colors-grey-300);
  font-family: var(--emu-semantic-font-families-body);
  color: var(--emu-common-colors-black);
  -webkit-font-smoothing: antialiased;
  font-size: 1em;
  position: relative;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

sup {
  vertical-align: super;
  top: var(--emu-common-spacing-none);
  font-size: 50%;
  line-height: 0;
  position: relative;
}

p, li {
  margin-bottom: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: block;
}

@media (max-width: 768px) {
  .tablet-desktop-view {
    display: none !important;
  }
}

.cq-Editable-dom--container .tablet-desktop-view {
  display: block !important;
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view, .mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none !important;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: block !important;
}

.no-pad {
  padding: var(--emu-common-spacing-none) !important;
}

.no-x-pad {
  padding-left: 0;
  padding-right: 0;
}

.no-y-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.u-content--centered {
  text-align: center;
}

.u-container--default {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 990px) {
  .u-container--default {
    max-width: 972px;
  }
}

.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg-color--grey {
  background-color: var(--emu-common-colors-grey-400);
}

.u-bg-color--light-grey {
  background-color: var(--emu-common-colors-grey-300);
}

.u-bg-color--lighter-violet {
  background-color: var(--emu-common-colors-violet-50);
}

.u-bg-color--light-violet {
  background-color: var(--emu-common-colors-violet-200);
}

.u-bg-color--violet {
  background-color: var(--emu-common-colors-violet-300);
}

.u-bg-color--dark-violet {
  background-color: var(--emu-common-colors-violet-600);
}

.u-bg-color--darker-violet {
  background-color: var(--emu-common-colors-violet-800);
}

[class*="u-text--color"] h1, [class*="u-text--color"] h2, [class*="u-text--color"] h3, [class*="u-text--color"] h4, [class*="u-text--color"] h5, [class*="u-text--color"] h6, [class*="u-text--color"] p, [class*="u-text--color"] li, [class*="u-text--color"] a, [class*="u-text--color"] span, [class*="u-text--color"] button {
  color: inherit;
}

.u-text--color-white {
  color: var(--emu-common-colors-white);
}

.u-text--color-black {
  color: var(--emu-common-colors-black);
}

.u-text--color-lighter-violet {
  color: var(--emu-common-colors-violet-100);
}

.u-text--color-light-violet {
  color: var(--emu-common-colors-violet-400);
}

.u-text--color-violet {
  color: var(--emu-common-colors-violet-500);
}

.u-text--color-medium-violet {
  color: var(--emu-common-colors-violet-600);
}

.u-text--color-dark-violet {
  color: var(--emu-common-colors-violet-700);
}

.u-text--color-darker-violet {
  color: var(--emu-common-colors-violet-900);
}

.u-text--color-light-grey {
  color: var(--emu-common-colors-grey-500);
}

.u-text--color-grey {
  color: var(--emu-common-colors-grey-600);
}

.u-text--color-dark-grey {
  color: var(--emu-common-colors-grey-700);
}

.u-font-family--heading {
  font-family: var(--emu-semantic-font-families-heading) !important;
}

.u-font-family--body {
  font-family: var(--emu-semantic-font-families-body) !important;
}

.u-font-family--mono {
  font-family: var(--emu-semantic-font-families-mono) !important;
}

[class*="u-font-family--"] h1, [class*="u-font-family--"] h2, [class*="u-font-family--"] h3, [class*="u-font-family--"] h4, [class*="u-font-family--"] h5, [class*="u-font-family--"] h6, [class*="u-font-family--"] p, [class*="u-font-family--"] li, [class*="u-font-family--"] a, [class*="u-font-family--"] span {
  font-family: inherit !important;
}

#home .banner__wrapper {
  margin-bottom: var(--emu-common-spacing-small);
  overflow: hidden;
}

#home .banner__image {
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  #home .banner__image {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

#home .banner__image img {
  width: 100%;
  display: flex;
}

@media (min-width: 768px) {
  #home .banner__image img {
    object-fit: cover;
    object-position: left top;
    height: 330px;
  }
}

#home .locator {
  padding-top: var(--emu-common-spacing-large);
  flex-direction: column;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

@media (min-width: 768px) {
  #home .locator {
    margin-bottom: var(--emu-common-spacing-small);
    padding-top: 20px;
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: 20px;
    padding-left: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-common-spacing-medium);
    flex-direction: row;
    align-items: center;
    min-height: 200px;
  }

  #home .locator > .container:first-child {
    width: 50%;
  }
}

#home .locator > .container:last-child {
  margin-left: var(--emu-common-spacing-small);
  position: relative;
}

@media (min-width: 768px) {
  #home .locator > .container:last-child {
    margin-left: 20%;
  }

  #home .locator__details {
    padding-top: 20px;
    padding-bottom: 22px;
    padding-left: 26px;
  }
}

@media (min-width: 990px) {
  #home .locator__details {
    padding-bottom: 24px;
  }
}

#home .locator__details-title .cmp-title__text {
  margin-bottom: 14px;
  margin-top: var(--emu-common-spacing-none);
  letter-spacing: .4px;
  font-size: 22px;
  line-height: 1.18182;
  font-weight: var(--emu-common-font-weight-600);
}

@media (min-width: 768px) {
  #home .locator__details-title .cmp-title__text {
    letter-spacing: .6px;
    margin-bottom: 12px;
    font-size: 28px;
    line-height: 1.39286;
  }
}

@media (min-width: 990px) {
  #home .locator__details-title .cmp-title__text {
    margin-bottom: 13px;
  }
}

#home .locator__details-description p {
  font-size: 11.5px;
  line-height: var(--emu-common-line-heights-narrow-large);
  letter-spacing: .4px;
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-xs);
}

@media (min-width: 768px) {
  #home .locator__details-description p {
    letter-spacing: .6px;
    margin-bottom: var(--emu-common-spacing-xxs);
    font-size: 13px;
    line-height: 21px;
  }
}

#home .locator__details-description p sup {
  font-size: 50%;
  line-height: 0;
}

#home .locator__details-description .emphasis {
  letter-spacing: .6px;
}

@media (min-width: 768px) {
  #home .locator__details-description .emphasis {
    letter-spacing: .9px;
  }
}

#home .locator__find-a-clinic-cta {
  letter-spacing: .95px;
  padding: 9px 30px 5px;
  font-size: 15px;
  line-height: 23px;
}

@media (min-width: 768px) {
  [lang="fr_ca"] #home .locator__details-description p {
    letter-spacing: .7px;
  }
}

[lang="fr_ca"] #home .locator__find-a-clinic-cta {
  text-align: center;
}

@media (min-width: 990px) {
  [lang="fr_ca"] #home .locator__find-a-clinic-cta {
    letter-spacing: 1.1px;
  }
}

#about .title-text__wrapper {
  padding-bottom: 27px;
}

@media (min-width: 990px) {
  #about .title-text__wrapper {
    padding-bottom: 37px;
  }
}

#about .title-text__description p {
  letter-spacing: .3px;
  margin-bottom: 20px;
}

.getting-started__faq {
  margin-top: 29px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .getting-started__faq {
    margin-bottom: 20px;
  }
}

.getting-started__faq > .text:first-child .getting-started__faq-item {
  padding-top: var(--emu-semantic-spacing-none);
  border: none;
  padding-bottom: 14px;
}

.getting-started__faq > .text:first-child .getting-started__faq-item h3 {
  top: 0;
}

.getting-started__faq-item {
  padding-top: var(--emu-common-spacing-medium);
  border-top: var(--emu-common-sizing-xxs) solid var(--emu-common-colors-grey-200);
  padding-bottom: 14px;
  padding-left: 30px;
  padding-right: 20px;
  position: relative;
}

.getting-started__faq-item h3 {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  color: var(--emu-common-colors-violet-900);
  font-family: var(--emu-semantic-font-families-heading);
  line-height: 1;
  position: absolute;
  top: 17px;
  left: 0;
}

.getting-started__faq-item h4 {
  margin-top: var(--emu-semantic-spacing-none);
  letter-spacing: .2px;
  margin-bottom: 6px;
  font-size: 13.5px;
  line-height: 22px;
}

.getting-started__faq-item h4 sup {
  font-size: 9px;
}

.getting-started__faq-item p {
  letter-spacing: .625px;
  font-size: 13px;
  line-height: 22px;
}

.getting-started__faq-item p .emphasis {
  letter-spacing: .9px;
}

.getting-started__faq-item p sup {
  font-size: 10px;
  line-height: 10px;
}

[lang="fr_ca"] .getting-started__faq-item p {
  letter-spacing: .58px;
}

#sitemap .title-text__wrapper {
  padding-bottom: 27px;
}

@media (min-width: 990px) {
  #sitemap .title-text__wrapper {
    padding-bottom: 37px;
  }
}

#sitemap .sitemap__links ul {
  margin-bottom: var(--emu-semantic-spacing-none);
  padding-left: 15px;
}

#sitemap .sitemap__links ul li {
  list-style-type: none;
  position: relative;
}

#sitemap .sitemap__links ul li:before {
  content: "";
  background-color: var(--emu-common-colors-violet-700);
  border-radius: 100%;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 7px;
  left: -15px;
}

#sitemap .sitemap__links ul li a {
  color: var(--emu-common-colors-violet-700);
  letter-spacing: .4px;
  font-size: 15.5px;
  line-height: 19px;
}

.error {
  padding-top: 88px;
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: 88px;
  padding-left: var(--emu-common-spacing-medium);
}

@media (min-width: 768px) {
  .error {
    padding-top: 164px;
    padding-bottom: 164px;
  }
}

.error__title .cmp-title__text {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: 4.8px;
  font-size: 96px;
  line-height: 1;
}

@media (min-width: 768px) {
  .error__title .cmp-title__text {
    letter-spacing: 8px;
    font-size: 160px;
  }
}

.error__subtitle .cmp-title__text {
  margin-bottom: var(--emu-common-spacing-medium);
  letter-spacing: .2px;
  line-height: 1;
}

.error__desc p {
  letter-spacing: .6px;
}

.error .button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.error__cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  letter-spacing: .8px;
  padding-top: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-large);
  margin-bottom: var(--emu-common-spacing-large);
  justify-content: center;
  min-width: 150px;
  height: 40px;
  line-height: 43px;
}

.footer__container {
  padding-top: 36px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .footer__container {
    padding-top: 53px;
    padding-bottom: 50px;
  }
}

@media (min-width: 990px) {
  .footer__container {
    padding-top: 52px;
  }
}

@media (min-width: 768px) {
  .footer__wrapper {
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
  }

  .footer__wrapper.cmp-container {
    max-width: 972px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) and (min-width: 768px) {
  .footer__wrapper > .container:last-child {
    flex-shrink: 0;
  }
}

.footer__description-container.cmp-container {
  margin-bottom: 18px;
}

@media (min-width: 768px) {
  .footer__description-container.cmp-container {
    margin-bottom: var(--emu-semantic-spacing-none);
    max-width: 765px;
    padding-right: 20px;
  }

  .footer__links.cmp-container {
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) {
  .footer__links.cmp-container {
    margin-bottom: 21px;
  }
}

.footer__links ul {
  padding-left: var(--emu-semantic-spacing-none);
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  letter-spacing: .35px;
  font-size: 11.5px;
  line-height: 22px;
}

.footer__links li {
  list-style-type: none;
  display: inline-block;
  position: relative;
}

.footer__links li:not(:first-child) a {
  margin-left: 6px;
  padding-left: 12px;
}

.footer__links li:not(:first-child) a:before {
  content: "";
  background-color: var(--emu-common-colors-grey-700);
  width: 1px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 6px;
}

.footer__links li:last-child a {
  padding-right: var(--emu-common-spacing-none);
}

.footer__links li a {
  color: var(--emu-common-colors-grey-700);
  padding-right: var(--emu-common-spacing-xs);
  text-decoration: none;
}

.footer__links li a:hover {
  color: var(--emu-common-colors-violet-600);
}

.footer__description p {
  letter-spacing: .4px;
  margin-top: 15px;
  font-size: 11.5px;
  line-height: 14px;
}

.footer__description .emphasis {
  letter-spacing: .5px;
}

.footer__logo-container {
  width: 98px;
}

@media (min-width: 768px) {
  .footer__logo-container {
    width: auto;
  }

  .footer__logo-container img {
    height: 113px;
    margin-top: -32px;
  }
}

[lang="fr_ca"] .footer__description p br {
  display: none;
}

@media (min-width: 990px) {
  [lang="fr_ca"] .footer__description p br {
    display: block;
  }
}

@media (min-width: 790px) {
  [lang="fr_ca"] .footer__links li {
    display: inline;
  }

  [lang="fr_ca"] .footer__links li:not(:first-child) a:before {
    top: 0;
  }
}

@media (min-width: 768px) {
  header .btx-header {
    background: var(--emu-common-colors-white) url("resources/images/body-background.png") repeat-x left top;
  }
}

header .btx-header__wrapper {
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-grey-500);
  justify-content: space-between;
  align-items: center;
  min-height: 96px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
}

@media (min-width: 768px) {
  header .btx-header__wrapper {
    background-color: var(--emu-common-colors-transparent);
    align-items: flex-end;
    min-height: 132px;
  }
}

@media (min-width: 990px) {
  header .btx-header__wrapper {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    max-width: 972px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  header .btx-header__wrapper > .image {
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

header .btx-header__logo img {
  width: 108px;
}

@media (min-width: 768px) {
  header .btx-header__logo img {
    width: 153px;
  }
}

header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  padding: var(--emu-common-spacing-none);
  border: var(--emu-common-border-width-none);
  line-height: 0;
  display: block;
}

header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2, header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:hover, header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:focus, header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:active {
  background-color: var(--emu-common-colors-transparent);
  border: var(--emu-common-border-width-none);
}

header .btx-header__mobile-cta.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 svg {
  margin: var(--emu-common-spacing-none);
  position: relative;
  top: -2px;
}

header .btx-header .btx-header__toggle.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  width: 28px;
}

header .btx-header .btx-header__toggle.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:hover svg {
  fill: #5b358d;
}

header .btx-header .btx-header__toggle.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 svg {
  fill: var(--emu-common-colors-violet-200);
  transition: fill var(--emu-common-other-time-transition-short);
  width: 28px;
  display: block;
  transform: scale(1.09);
}

header .btx-header__mobile-nav-container {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: 768px) {
  header .btx-header__mobile-nav-container {
    display: none;
  }
}

header .btx-header__mobile-nav-container.js-toggle-on {
  display: block;
}

header .btx-header__mobile-nav-container .aaaem-container {
  background-color: inherit;
}

header .btx-header__mobile-nav-topbar {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: var(--emu-common-spacing-small);
  border-bottom: var(--emu-component-lists-navigation-banner-item-border-width) solid var(--emu-component-lists-navigation-banner-item-color-border-default-light);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header .btx-header__mobile-nav-topbar .btx-header__logo img {
  width: 100px;
}

header .btx-header__mobile-nav-close.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  justify-content: center;
  align-items: center;
  width: 24px;
  padding: 5px;
  display: flex;
}

header .btx-header__lang-switch-container {
  margin-top: 20px;
  margin-bottom: 5px;
  padding-left: 20px;
  display: flex;
}

@media (min-width: 768px) {
  header .btx-header__lang-switch-container {
    z-index: var(--emu-common-other-z-index-overlay);
    background-color: var(--emu-common-colors-violet-50);
    margin: var(--emu-common-spacing-none);
    padding: var(--emu-common-spacing-none);
    z-index: var(--emu-common-other-z-index-header);
    justify-content: space-around;
    align-items: center;
    display: flex;
    position: fixed;
    top: 0;
    right: 12%;
  }
}

header .btx-header__lang-switch-container > .button:not(:last-child) a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2, header .btx-header__lang-switch-container > .button:not(:last-child) button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  border-right: var(--emu-common-border-width-none);
}

header .btx-header__lang-switch-container > .button a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2, header .btx-header__lang-switch-container > .button button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
  font-size: var(--emu-common-font-sizes-narrow-medium);
  letter-spacing: .25px;
  padding: 6px 12px 5px;
  line-height: normal;
}

@media (min-width: 768px) {
  header .btx-header__lang-switch-container > .button a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2, header .btx-header__lang-switch-container > .button button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
    border: var(--emu-common-border-width-none);
    transition: all var(--emu-common-other-time-transition-short);
    padding-top: 9px;
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: 6px;
    padding-left: var(--emu-common-spacing-small);
    font-size: 14px;
    line-height: 1;
  }

  header .btx-header__lang-switch-container > .button a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:hover, header .btx-header__lang-switch-container > .button a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:active, header .btx-header__lang-switch-container > .button a.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:focus, header .btx-header__lang-switch-container > .button button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:hover, header .btx-header__lang-switch-container > .button button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:active, header .btx-header__lang-switch-container > .button button.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2:focus {
    background-color: var(--emu-common-colors-violet-800);
  }

  header .btx-header__lang-switch-cta--active.emu-button-v2.emu-button-v2.emu-button-v2.emu-button-v2 {
    font-family: var(--emu-semantic-font-families-heading);
  }

  header .btx-header__lang-switch-separator {
    position: relative;
    top: 2px;
  }

  header .btx-header__lang-switch-separator p {
    font-size: 14px;
  }
}

header .btx-header__mobile-secondary-nav ul li a {
  border: var(--emu-common-border-width-none);
}

.emu-navigation__content-wrapper {
  position: static;
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav ul li:not(:last-child) a {
    border-right: var(--emu-component-lists-navigation-banner-item-border-width) solid var(--emu-component-lists-navigation-banner-item-color-border-default-light);
  }

  .emu-navigation__content-wrapper nav ul li:last-child a {
    padding-right: var(--emu-common-spacing-none);
  }
}

.emu-navigation__content-wrapper nav ul li a {
  letter-spacing: .2px;
  border-top: var(--emu-common-border-width-none);
  border-left: var(--emu-common-border-width-none);
  border-right: var(--emu-common-border-width-none);
  font-size: 13px;
  line-height: normal;
  position: relative;
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav ul li a {
    border: var(--emu-common-border-width-none);
    color: var(--emu-common-colors-grey-600);
    letter-spacing: .6px;
    font-size: 17px;
    line-height: 1.3;
  }
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav ul li a {
    text-shadow: 0 1px;
    letter-spacing: .65px;
    padding: 6px 23px 13px;
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav ul li a:hover {
    color: var(--emu-common-colors-violet-500);
  }
}

.emu-navigation__content-wrapper nav ul li a:after {
  content: "";
  background: url("resources/images/right-arrow.svg") center / cover no-repeat;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .emu-navigation__content-wrapper nav ul li a:after {
    display: none;
  }

  [lang="fr_ca"] .emu-navigation__content-wrapper nav a {
    letter-spacing: .75px;
    padding-bottom: 13px;
  }
}



.title-text__wrapper {
  padding: 34px 20px 30px;
}

@media (min-width: 768px) {
  .title-text__wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 972px) {
  .title-text__wrapper {
    padding: 44px 40px 40px;
  }
}

.title-text__title {
  margin-bottom: 19px;
}

.title-text__title .cmp-title__text {
  margin-top: var(--emu-semantic-spacing-none);
  margin-bottom: var(--emu-semantic-spacing-none);
  line-height: 36px;
}

.title-text__description p {
  letter-spacing: .4px;
  margin-bottom: 21px;
  font-size: 15.5px;
  line-height: 26px;
}

@media (min-width: 768px) {
  .title-text__description p {
    letter-spacing: .45px;
  }
}

.title-text__description p .emphasis {
  letter-spacing: .8px;
}

@media (min-width: 768px) {
  .title-text__description p .emphasis {
    letter-spacing: .4px;
  }
}

[lang="fr_ca"] .title-text__description p {
  letter-spacing: .5px;
}

/*# sourceMappingURL=main.css.map */
