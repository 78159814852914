.emu-navigation {
  &__content-wrapper {
    position: static;

    // needed to increase the specificity
    nav ul {
      li {
        &:not(:last-child) {
          a {
            @include mq('medium') {
              border-right: var(
                  --emu-component-lists-navigation-banner-item-border-width
                )
                solid
                var(
                  --emu-component-lists-navigation-banner-item-color-border-default-light
                );
            }
          }
        }

        &:last-child {
          a {
            @include mq('medium') {
              padding-right: var(--emu-common-spacing-none);
            }
          }
        }

        a {
          font-size: 13px;
          line-height: normal;
          letter-spacing: 0.2px;
          border-top: var(--emu-common-border-width-none);
          border-left: var(--emu-common-border-width-none);
          border-right: var(--emu-common-border-width-none);
          position: relative;

          @include mq('medium') {
            border: var(--emu-common-border-width-none);
            color: var(--emu-common-colors-grey-600);
            font-size: 17px;
            line-height: 1.3;
            letter-spacing: 0.6px;
          }

          @include mq('large') {
            font-size: 19px;
            text-shadow: 0 1px currentColor; // font weight is looking too bold. Getting the boldness via text shadow
            letter-spacing: 0.65px;
            padding-top: 6px;
            padding-right: 23px;
            padding-bottom: 13px;
            padding-left: 23px;
          }

          &:hover {
            @include mq('medium') {
              color: var(--emu-common-colors-violet-500);
            }
          }

          &::after {
            content: '';
            width: 6px; // as per live site
            height: 10px; // as per live site
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            background: url('../../assets/images/right-arrow.svg') center
              no-repeat;
            background-size: cover;

            @include mq('medium') {
              display: none;
            }
          }
        }
      }
    }
  }
}

[lang='fr_ca'] {
  .emu-navigation {
    &__content-wrapper {
      nav {
        a {
          @include mq('medium') {
            padding-bottom: 13px;
            letter-spacing: 0.75px;
          }
        }
      }
    }
  }
}
